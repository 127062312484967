<template>
	<div>
		<v-icon
			link
			size="20"
			class="input-addon primary--text"
			:class="customClass"
			v-on:click="dialog = !dialog"
		>
			mdi-cog
		</v-icon>
		<v-dialog v-model="dialog" max-width="600px" persistent scrollable>
			<v-card>
				<v-card-title class="py-4 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">
						{{ title }}
						<v-avatar color="white" size="30" class="text-black">{{ create_setting.length }}</v-avatar>
					</div>
					<v-spacer></v-spacer>
				</v-card-title>
				<v-card-text class="px-4" style="height: 500px">
					<v-form ref="industryService" v-model.trim="formValid">
						<draggable v-model="create_setting" handle=".handle" class="row mx-0">
							<template v-for="(data, index) in create_setting">
								<v-col md="12" cols="12" class="my-auto py-0 position-relative" :key="index">
									<div class="d-flex align-center">
										<!-- <span class="me-2 mt-3">{{ index + 1 }}</span> -->
										<v-icon class="mt-1 handle draggable-action" color="blue darken-4">mdi-drag</v-icon>
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											:placeholder="title"
											v-model="create_setting[index].text"
											:rules="[vrules.required(create_setting[index].text, 'Industry')]"
											:class="{ required: !create_setting[index].text }"
										>
										</TextInput>

										<template v-if="data.id">
											<v-icon class="red--text cursor-pointer" v-on:click="deleteSetting(data.id)"
												>mdi-delete</v-icon
											>
										</template>
										<template v-else>
											<v-icon class="red--text cursor-pointer" v-on:click="deletefield(index)"
												>mdi-delete</v-icon
											>
										</template>
									</div>
								</v-col>
							</template>
						</draggable>
						<div class="px-3">
							<v-btn tile depressed color="blue darken-4" class="white--text" v-on:click="addField">
								<v-icon>mdi-plus</v-icon>
								Add
							</v-btn>
						</div>
					</v-form>
				</v-card-text>
				<v-card-action class="text-end">
					<div class="py-3 px-4 border-top bg-white">
						<v-btn tile depressed class="darken-4 mt-0 cancel_btn" v-on:click="closedialog">Close</v-btn>
						<v-btn
							tile
							depressed
							color="white--text"
							class="blue darken-4 ms-2 mt-0 save_btn"
							:disabled="!formValid"
							v-on:click="addSetting"
						>
							Save
						</v-btn>
					</div>
				</v-card-action>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
// import Dialog from "@/view/components/Dialog.vue";
import draggable from "vuedraggable";
import TextInput from "@/view/components/TextInput";
import { POST, GET, DELETE } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "ManageAutocomplete",
	props: {
		title: {
			type: String,
			default: null,
		},
		typeId: {
			default: 0,
		},
		customClass: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			dialog: false,
			pageLoading: false,
			formValid: true,
			create_setting: [
				{
					text: null,
				},
			],
		};
	},

	methods: {
		closedialog() {
			this.getSetting();

			this.dialog = false;
		},
		addField() {
			this.create_setting.push({
				text: null,
			});
		},
		deletefield(index) {
			this.create_setting.splice(index, 1);
		},
		addSetting() {
			const unique_array_payload = this.create_setting.reduce((uniq, curr_val) => {
				if (!uniq.some((item) => item.text == curr_val.text)) {
					uniq.push(curr_val);
				}
				return uniq;
			}, []);

			if (unique_array_payload.length) {
				this.$store
					.dispatch(POST, {
						url: "lead-setting-update",
						data: {
							type: this.typeId,
							status: unique_array_payload,
						},
					})
					.then(() => {
						this.dialog = false;
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: `Success! ${this.title} updated successfully.`,
							},
						]);
						this.$emit("update", true);
						this.getSetting();
					})
					.catch((error) => {
						console.log({ error });
					})
					.finally(() => {
						this.pageLoading = false;
					});
			}
		},
		getSetting() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `single-setting/${this.typeId}`,
				})
				.then((data) => {
					if (data && data.length) {
						this.create_setting = data;
					} else {
						this.create_setting = [
							{
								text: null,
							},
						];
					}
					this.$emit("create_setting", data);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		deleteSetting(id) {
			this.$store
				.dispatch(DELETE, {
					url: `lead-setting/${id}`,
				})
				.then(() => {
					this.getSetting();
					this.$refs["industryService"].validate();
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
	components: {
		TextInput,
		draggable,
	},
	mounted() {
		this.getSetting();
	},
};
</script>

<style scoped>
/* Add some basic styling */
button {
	margin-top: 10px;
	padding: 5px 10px;
	cursor: pointer;
}
</style>
